<template>
  <div>
    <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
    <TABS :tabChange='onCallbackTab' :list="tabsArr"></TABS>
    <TABLE :selectChange='onCallbackSelect' :buttonChange='onCallbackButton' :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>

  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { getTower, delTower } from '@/api/project'
export default {
  name: 'TownerManage',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
  },
  data() {
    return {
      searchArr: [
        {
          name: '杆塔名称',
          type: 'input',
          options: [],
          key: 'towerName',
        },
        {
          name: '杆塔类型',
          type: 'picker',
          options: [],
          key: 'towerType',
        },
        {
          name: '所属工程',
          type: 'picker',
          options: [],
          key: 'fenceProject',
        },
        {
          name: '杆塔电压',
          type: 'picker',
          options: [],
          key: 'towerPower',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '新增杆塔',
          func: '',
          isLoading: false,
        },
        {
          type: 'primary',
          name: '导入工程',
          func: '',
          isLoading: false,
        },
        {
          type: 'primary',
          name: '下载模板',
          func: '',
          isLoading: false,

        },
        {
          type: 'danger',
          name: '批量删除',
          func: 'removeTower',
          isLoading: false,

        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'sort',
          label: '排序',
          width: '130',
        },
        {
          prop: 'name',
          label: '杆塔名称',
          width: '130',
        },
        {
          prop: 'type_id',
          label: '杆塔类型',
          width: '130',
        },
        {
          prop: 'voltage',
          label: '杆塔电压',
          width: '130',
        },
        {
          prop: 'p_id',
          label: '所属工程',
          width: '130',
        },
        {
          prop: 'location',
          label: '地理位置',
          width: '130',
        },
        {
          prop: 'progress',
          label: '施工进度',
          width: '130',
        },
        {
          prop: 'create_time',
          label: '完成情况',
          width: '130',
        },
        {
          prop: 'desc',
          label: '施工人数',
          width: '130',
        },
        {
          prop: 'create_time',
          label: '创建时间',
          width: '130',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeTower',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        type_id: '',
        name: '',
        p_id: '',
        voltage: '',
      },
      ids: '',
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_id = this.$store.getters.USER_ID
      getTower(this.formData).then((res) => {
        console.log(res, '这是杆塔列表')
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = this.butnArr
        })
        this.dataArr = result.data
      })
    },

    // 删除杆塔
    removeTower: function(key, index) {
      if (!this.ids) {
        this.$toast('请选择要删除的杆塔')
        this[key][index].isLoading = false
        return false
      }
      delTower(this.ids).then(res => {
        this.$toast(res.message)
        this._loadData()
        key && index && (this[key][index].isLoading = false)
      })
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { towerName, towerType, fenceProject, towerPower } = data
      this.formData.voltage = towerPower
      this.formData.p_id = fenceProject
      this.formData.name = towerName
      this.formData.type_id = towerType
      this._loadData()
    },

    // 收到 tab 回调
    onCallbackTab: function({ item, index }) {
      this.tabsArr[index].isLoading = true
      this[item.func]('tabsArr', index)
    },

    // 收到 选择 回调
    onCallbackSelect: function(ids) {
      this.ids = ids
    },

    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this.ids = item.id
      this[func]()
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
